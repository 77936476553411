import React from "react";
import { useTranslation } from "react-i18next";
import { graphql } from 'gatsby';


import { Button } from '../components/01_atoms/Button/Button';
import { Heading } from "../components/01_atoms/Heading/Heading";
import { Section } from "../components/03_organisms/Section/Section";
import Header from "../components/03_organisms/Header/Header";
import Hero from "../components/03_organisms/Hero/Hero";
import Contact from "../components/03_organisms/Contact/Contact";
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph";
import ScrollIndicator from "../components/01_atoms/ScrollIndicator/ScrollIndicator";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Footer from "../components/03_organisms/Footer/Footer";
import { Image } from "../components/01_atoms/Image/Image";
import { Video } from "../components/01_atoms/Video/Video";
import Animate from "../components/02_molecules/Animate/Animate";
import { HeadInject } from "../components/03_organisms/HeadInject/HeadInject";

import * as styles from './styles/work.module.scss';


const Work = ({ data,pageContext, ...props }) => {

	// Define current item
	const currentItem = data.allDatoCmsWork.edges.filter((el, index) => {
		return el.node.position === data.datoCmsWork.position
	})
	
	// Define next slug
	let nextSlug = (currentItem[0].next) ? currentItem[0].next.slug : data.allDatoCmsWork.edges[0].node.slug;	

	// read language
	const {t} = useTranslation();

	// render html
	return (
		<div>
			<HeadInject />
			<HelmetDatoCms seo={data.datoCmsWork.seoMetaTags} />
			
			<Header>
				<Button to="/" size="s">{t('case_back_cta')}</Button>
			</Header>

			<Hero image={data.datoCmsWork.headerImage.gatsbyImageData}>
				<Animate delay="1"><ScrollIndicator /></Animate>
				<div>
					<Animate><Heading element="h1" size="xxl" weight="900" spacing="l">{data.datoCmsWork.title}</Heading></Animate>
					<Animate delay=".5"><Heading element="h1" size="m" weight="400" uppercase>{data.datoCmsWork.subtitle}</Heading></Animate>
				</div>
			</Hero>

			<Section fullHeight hasContent id="work" background="blue" slanted="bottom">
				<Animate>
					<Paragraph>
						<div dangerouslySetInnerHTML={{__html: data.datoCmsWork.description}}></div>
					</Paragraph>
				</Animate>


	
					{/* {data.datoCmsWork.gallery.map((gallery_item,index) => (

					(() => {
						switch (gallery_item.format) {
						case "mp4": return <Video key={index} url={gallery_item.url} spacing="l"  />
						default:    return <Image key={index} image={gallery_item.gatsbyImageData} spacing="l" />
						}
					})()

					))} */}
					<div className={styles.workblocks}>
						{data.datoCmsWork.modularBlocks.map((modularBlock,index) => (
							<div key={index}>
								{modularBlock.youtubeVideo ? <Animate><Video  uid={modularBlock.youtubeVideo.providerUid} width={modularBlock.youtubeVideo.width} height={modularBlock.youtubeVideo.height} title={modularBlock.youtubeVideo.title} spacing="l"  /></Animate> : null }
								{modularBlock.images ?  modularBlock.images.map((image,index) => (
									<Animate key={index}>
										<Image key={index} image={image.gatsbyImageData} spacing="l" />
									</Animate>
								)) : null }
							</div> 
						))}
					 </div>
		
				<Animate>
					<Heading size="xl" align="center" spacing="l">
						{t('case_next_title')}
					</Heading>
				</Animate>
				<Animate>					
					<Paragraph align="center">
						<Button to={`/${nextSlug}`} size="s">{t('case_next_cta')}</Button>
					</Paragraph>
				</Animate>	
			</Section>

			<Contact background />
			<Footer />

			
			

		
		</div>
	)
}

export default Work;


export const query = graphql`
  query WorkQuery($slug: String!) {
    datoCmsWork(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
	  title
	  position
	  slug
	  subtitle
	  description
	  modularBlocks {
		... on DatoCmsVideo {
		  videoDescription {
			value
		  }
		  youtubeVideo {
			url
			width
			height
			title
			providerUid
		  }
		}
		... on DatoCmsImageGroup {
		  images {
			gatsbyImageData
		  }
		}
	  }
	  headerImage {
		gatsbyImageData(layout: FULL_WIDTH)
	  }
    }
	allDatoCmsWork(sort: {fields: position, order: ASC}) {
		edges {
			node {
				slug
				position
			}
			next {
				slug
			}
		}
	}
  }
`