import React from 'react';
import * as styles from './styles/video.module.scss';
import cx from 'classnames';


export const Video = ({uid,width,height,title,spacing,  ...props }) => {

    // define classes
    const classname = cx(
        styles.video,
        styles[`spacing_${spacing}`]
    );  

    return (
        <div className={classname}>
            <iframe width={width} height={height} title={title}
            src={`https://www.youtube.com/embed/${uid}`}>
            </iframe>
        </div>
    );
    
};